export default [
  // {
  //   // 表格表头名称
  //   title: '系统ID',
  //   // 该列的宽度
  //   width: 310,
  //   // 该列对应的属性名
  //   property: 'id',
  //   // 该列在表头支持何种方式搜索 分为''不支持 input文本框 select下拉列表 cascader级联选择
  //   search: 'input',
  //   // 是否支持表头显示排序
  //   sort: false,
  //   // 是否支持直接在数据列表页面进行修改 如果支持就与表头搜索使用相同类型的输入框进行修改
  //   edit: false,
  // },
  // {
  //   title: '系统ID',
  //   width: 310,
  //   property: 'id',
  //   search: 'input',
  //   sort: false,
  //   edit: false,
  // },
  {
    title: '机构名称',
    'min-width': 300,
    property: 'name',
    search: 'input',
    sort: false,
    edit: false,
    fixed: true,
  },
  {
    title: '二级分类',
    width: 100,
    property: 'hco_type',
    // search: 'select',
    sort: false,
    edit: false,
  },
  {
    title: '类型细分',
    width: 140,
    property: 'type_name',
    search: 'cascader',
    sort: false,
    edit: true,
  },
  // {
  //   title: '医院等级',
  //   width: 300,
  //   property: 'hospital_grade',
  //   search: 'input',
  //   sort: false,
  //   edit: true,
  // },
  {
    title: '医院级别',
    width: 120,
    property: 'hospital_level',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '所有制性质',
    width: 120,
    property: 'ownership',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '是否医保',
    width: 120,
    property: 'is_medical_insurance',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '卫健委名称',
    width: 200,
    property: 'nhc_name',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '曾用名',
    'min-width': 300,
    property: 'former_name',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '别名',
    width: 200,
    property: 'alias_name',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '行政区划',
    'min-width': 300,
    property: 'admin_division',
    search: 'cascader',
    sort: false,
    edit: false,
  },
  {
    title: '地址',
    'min-width': 300,
    property: 'address',
    search: 'input',
    sort: false,
    edit: true,
  },
  {
    title: '经营状态',
    width: 100,
    property: 'business_status',
    search: 'select',
    sort: false,
    edit: true,
  },
  {
    title: '统一社会信用代码',
    width: 180,
    property: 'unified_social_credit_code',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '成立日期',
    width: 150,
    property: 'establishment_date',
    search: 'date',
    sort: true,
    edit: false,
    format: true,
  },
  {
    title: '是否存疑',
    width: 100,
    property: 'in_doubt',
    search: 'select',
    sort: false,
    edit: false,
  },
  {
    title: '数据来源',
    width: 150,
    property: 'source',
    search: 'select',
    sort: false,
    edit: false,
  },
  {
    title: '质量负责人',
    width: 120,
    property: 'qa_persons',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '创建人',
    width: 120,
    property: 'create_user',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '创建时间',
    width: 180,
    property: 'create_time',
    search: 'date',
    sort: true,
    edit: false,
    format: true,
  },
  {
    title: '更新人',
    width: 120,
    property: 'update_user',
    search: 'input',
    sort: false,
    edit: false,
  },
  {
    title: '更新时间',
    width: 180,
    property: 'update_time',
    search: 'date',
    sort: true,
    edit: false,
    format: true,
  },
];
